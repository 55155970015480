<template>
    <u-divider-group>
        <li v-for="link in links" :key="link.title">
            <a v-if="link.href" :href="link.href" target="_blank" class="grey--text text--lighten-1">{{ link.title }}</a>
            <router-link v-else :to="link.to" class="grey--text text--lighten-1">{{ link.title }}</router-link>
        </li>
    </u-divider-group>
</template>

<script>
import UDividerGroup from "@/components/publish/styles/groups/u-divider-group.vue";

export default {
    props: {},
    components: {
        UDividerGroup,
    },
    data() {
        return {
            links: [
                { title: "회사소개", to: "" },
                { title: "자주묻는질문", to: "" },
                { title: "개인정보처리방침", to: "" },
                { title: "이용약관", to: "" },
                { title: "공지사항", to: "" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
