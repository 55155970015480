<template>
    <footer-head class="footer-head--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </footer-head>
</template>

<script>
import FooterHead from "@/sets/styles/footers/footer-head.vue";

export default {
    props: {},
    components: {
        FooterHead,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer-head--basic {
    color: #fff;
    background-color: var(--v-grey-darken4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 14px 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-head--basic {
        padding: 20px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
