<template>
    <footer-body class="footer-body--basic" :bodyLeftAttrs="{ cols: '12', md: '', order: '2', 'order-md': '1' }" :bodyRightAttrs="{ cols: '12', md: 'auto', order: '1', 'order-md': '2', class: 'd-flex' }">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </footer-body>
</template>

<script>
import FooterBody from "@/sets/styles/footers/footer-body.vue";

export default {
    props: {},
    components: {
        FooterBody,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer-body--basic {
    color: #fff;
    background-color: var(--v-grey-darken4);
    padding: 30px 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-body--basic {
        padding: 48px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
