<template>
    <logo class="logo--dark">
        <slot />
    </logo>
</template>

<script>
import Logo from "@/components/publish/styles/headers/logo.vue";

export default {
    props: {
        To: { type: String, default: "/" },
        Title: { type: String, default: "홈으로 이동" },
    },
    components: {
        Logo,
    },
    data() {
        return {
            to: "",
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
