<template>
    <sub-head v-bind="{ tabsTag, tabsAttrs, tabTag, tabAttrs, ...$attrs }">
        <template #title>
            <tit-primary class="white--text">
                <slot name="title" />
            </tit-primary>
        </template>
    </sub-head>
</template>

<script>
import SubHead from "@/sets/styles/pages/sub-head.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";

export default {
    props: {
        tabsTag: String,
        tabsAttrs: { type: Object, default: () => ({}) },
        tabTag: String,
        tabAttrs: { type: Object, default: () => ({}) },
    },
    components: {
        SubHead,
        TitPrimary,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped></style>
