var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-footer-basic', {
    staticClass: "footer--dark"
  }, [_c('u-footer-head-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('footer-link')];
      },
      proxy: true
    }])
  }), _c('u-footer-body-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-medium', {
          staticClass: "txt--sm"
        }, [_vm._v(" 사이트명 ")])], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('footer-info')], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-default', {
          staticClass: "txt--xs grey--text text--lighten-1"
        }, [_vm._v(" Copyright 2023. All Rights Reserved.")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('v-row', {
          staticClass: "flex-column",
          attrs: {
            "justify": "space-between",
            "align-md": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('logo-secondary', {
          staticClass: "ml-md-auto"
        }, [_vm._v("LOGO")])], 1), _c('v-col', {
          staticClass: "d-flex mt-auto",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "fab": "",
            "color": "grey",
            "href": "https://blog.naver.com/",
            "target": "_blank"
          }
        }, [_c('icon-blog')], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "fab": "",
            "color": "grey",
            "href": "https://www.youtube.com/",
            "target": "_blank"
          }
        }, [_c('icon-youtube')], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "fab": "",
            "color": "grey",
            "href": "https://www.facebook.com/",
            "target": "_blank"
          }
        }, [_c('icon-facebook')], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "fab": "",
            "color": "grey",
            "href": "https://www.instagram.com/",
            "target": "_blank"
          }
        }, [_c('icon-insta')], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }