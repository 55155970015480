<template>
    <u-btn-icon-outlined-rounded v-bind="$attrs" v-scroll="onScroll" fixed large color="primary" class="btn-top-primary" @click="$vuetify.goTo(target, options)">
        <icon-arrow-primary size="small" direction="up" />
    </u-btn-icon-outlined-rounded>
</template>

<script>
import UBtnIconOutlinedRounded from "@/components/publish/styles/buttons/u-btn-icon-outlined-rounded.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

export default {
    props: {},
    components: {
        UBtnIconOutlinedRounded,
        IconArrowPrimary,
    },
    data() {
        return {
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    mounted() {},
    methods: {
        onScroll: function () {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            var scrollTopBtn = document.querySelector(".btn-top-primary");
            var innerHeight = window.innerHeight;
            var scrollHeight = document.body.scrollHeight;
            var footer = document.querySelector(".footer");
            var footerHeight = footer.offsetHeight + 12;

            if (scrollTopBtn !== null) {
                if (scrollTop > window.innerHeight / 2) {
                    scrollTopBtn.classList.add("active");
                } else {
                    scrollTopBtn.classList.remove("active");
                }

                if (scrollTop + innerHeight >= scrollHeight) {
                    scrollTopBtn.classList.add("bottom");
                    scrollTopBtn.style.setProperty("bottom", footerHeight + "px", "important");
                } else {
                    scrollTopBtn.classList.remove("bottom");
                    scrollTopBtn.style.bottom = "";
                }
            }
        },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-top-primary
.btn-top-primary {
    bottom: var(--btn-top-position-bottom) !important;
    right: var(--btn-top-position-right) !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    -webkit-transition: all ease-out 0.2s;
    -ms-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
    will-change: unset !important;
    &.v-btn:not(.v-btn--has-bg) {
        background-color: #fff !important;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
