var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-header-fixed', {
    staticClass: "header--lnb-all"
  }, [_c('u-header-head-basic', {
    staticClass: "d-none d-xl-block",
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('user-items-primary')];
      },
      proxy: true
    }])
  }), _c('u-header-body-basic', {
    staticClass: "d-none d-xl-block",
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('logo-primary', [_vm._v("LOGO")])];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('u-gnb-filled', {
          staticClass: "text-center",
          attrs: {
            "gnbName": "gnb",
            "lnbTag": "u-lnb-all-opacity-black"
          }
        })];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('language-items-primary', {
          attrs: {
            "id": "header-language-items-primary"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('btn-hamburger-primary', {
          staticClass: "btn-hamburger--pc"
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('sitemap-primary')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }