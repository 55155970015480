<template>
    <u-inline-group>
        <li v-for="info in infos" :key="info.title" :class="info.clear == true ? 'clear' : ''">
            <u-txt-default class="txt--xs">
                {{ info.title }} <span class="grey--text text--lighten-1">{{ info.text }}</span>
            </u-txt-default>
        </li>
    </u-inline-group>
</template>

<script>
import UInlineGroup from "@/components/publish/styles/groups/u-inline-group.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UInlineGroup,
        UTxtDefault,
    },
    data() {
        return {
            infos: [
                { title: "대표자명", text: "홍길동" },
                { title: "주소", text: "서울특별시 구로구 디지털로29길 38 에이스테크노타워3차 707호", clear: true },
                { title: "사업자등록번호", text: "00-0000-0000" },
                { title: "이메일", text: "email@gmail.com" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
