<template>
    <v-app>
        <!-- S: HEADER -->
        <header-primary v-if="$vuetify.breakpoint.xlOnly" />
        <template v-else>
            <mobile-header-primary />
            <mobile-nav-primary />
        </template>
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="contentsHead" />
            <div id="contents">
                <slot />
            </div>
            <slot name="contentsFoot" />
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <footer-primary />
        <!-- E: FOOTER -->

        <btn-top-primary />
    </v-app>
</template>

<script>
import HeaderPrimary from "@/sets/parents/headers/header-primary.vue";
import MobileHeaderPrimary from "@/sets/parents/headers/mobile-header-primary.vue";
import MobileNavPrimary from "@/sets/parents/headers/mobile-nav-primary.vue";
import FooterPrimary from "@/sets/parents/footers/footer-primary.vue";
import BtnTopPrimary from "@/components/publish/parents/buttons/btn-top-primary.vue";

export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        HeaderPrimary,
        MobileHeaderPrimary,
        MobileNavPrimary,
        FooterPrimary,
        BtnTopPrimary,
    },
    mounted() {
        // 라우터 이동 시 스크롤 상단부터 시작
        window.scrollTo(0, 0);

        window.addEventListener("copy", this.preventCopy);
        window.addEventListener("contextmenu", this.preventCopy);
        window.addEventListener("selectstart", this.preventDefault);
        window.addEventListener("dragstart", this.preventDefault);

        this.init();
    },
    destroyed() {
        window.removeEventListener("copy", this.preventCopy);
        window.removeEventListener("contextmenu", this.preventCopy);
        window.removeEventListener("selectstart", this.preventDefault);
        window.removeEventListener("dragstart", this.preventDefault);
    },
    methods: {
        init() {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 200,
                    duration: 600,
                    throttleDelay: 100,
                });
            }, 100);
        },
    },
};
</script>

<style lang="scss" scoped>
.app--main {
    ::v-deep {
        #contents {
            padding: 0;
        }
    }
}
</style>
